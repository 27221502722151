// @file Surface QR code
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { transformUrl } from '@@/bits/location'
import { safeSessionStorage } from '@@/bits/safe_storage'
import { useSurfaceStore } from '@@/pinia/surface'
import type { HashId } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

const PINNED_QR_CODE_STORAGE_KEY = 'pin_qr_walls'

const getPinnedQrCodeFromStorage = (): Set<string> => {
  return new Set(JSON.parse(safeSessionStorage.getItem(PINNED_QR_CODE_STORAGE_KEY) ?? '[]'))
}

const setXPinnedQrCodeValue = (xPinnedQrCode: boolean, wallHashId: HashId): void => {
  try {
    const xPinnedQrCodeWallValues = getPinnedQrCodeFromStorage()
    xPinnedQrCode ? xPinnedQrCodeWallValues.add(wallHashId) : xPinnedQrCodeWallValues.delete(wallHashId)
    safeSessionStorage.setItem(PINNED_QR_CODE_STORAGE_KEY, asciiSafeStringify([...xPinnedQrCodeWallValues]))
  } catch {
    safeSessionStorage.setItem(PINNED_QR_CODE_STORAGE_KEY, '[]')
  }
}

const getXPinnedQrCodeValue = (wallHashId: HashId): boolean => {
  try {
    const xPinnedQrCodeWallValues = getPinnedQrCodeFromStorage()
    return xPinnedQrCodeWallValues.has(wallHashId)
  } catch {
    return false
  }
}

export const useSurfaceQrCodeStore = defineStore('surfaceQrCode', () => {
  const surfaceStore = useSurfaceStore()

  // State
  const xPinnedQrCode = ref<boolean>(getXPinnedQrCodeValue(surfaceStore.wallAttributes.hashid as HashId))

  // Getters
  const pinnedQrCodeUrl = computed<string>(() =>
    // Refer to `Surface::LinksController::QR_CODE_SOURCES`
    surfaceStore.wallAttributes.links?.qr_code
      ? transformUrl(surfaceStore.wallAttributes.links?.qr_code, { search: { source: 'pin' } })
      : '',
  )

  const togglePinnedQrCode = (): void => {
    xPinnedQrCode.value = !xPinnedQrCode.value
    setXPinnedQrCodeValue(xPinnedQrCode.value, surfaceStore.wallAttributes.hashid as HashId)
  }

  return {
    // State
    xPinnedQrCode,

    // Getters
    pinnedQrCodeUrl,
    togglePinnedQrCode,
  }
})
